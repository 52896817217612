<template>
  <div class="mb-50">
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/engineering/index' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>造价软件</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>
      <el-form
        :model="form"
        ref="form"
        label-width="92px"
        label-position="right"
        :inline="false"
        class="demo-form-inline"
      >
        <!-- 搜索 -->
        <div class="border-b" style="margin-bottom: 30px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="软件分类：" prop="activeDateIdx">
                <div style="display: flex">
                  <span
                    @click="handDate(item.id)"
                    style="
                      white-space: nowrap;
                      margin-right: 20px;
                      padding: 0 10px;
                      cursor: pointer;
                    "
                    :class="item.id === activeDateIdx && 'is_active'"
                    v-for="(item, index) in dateList"
                    :key="index"
                    >{{ item.name }}</span
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="关键字：" prop="code">
                <div style="display: flex">
                  <el-input
                    style="margin-right: 30px"
                    v-model="form.code"
                    placeholder="输入关键字搜索"
                  ></el-input>
                  <el-button
                    style="margin-right: 20px"
                    type="primary"
                    @click="getSoftwareMrgPage"
                    >搜索</el-button
                  >
                  <el-button @click="handleReset()">重置</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <!-- 列表 -->
        <div>
          <el-row>
            <el-table
                v-if="tableData.length"
              :data="tableData"
              :show-header="false"
              :expand-row-keys="expandedRows"
              row-key="id"
              border
              :fit="true"
              v-loading="loading"
            >
              <el-table-column style="height: 218px">
                <el-row slot-scope="{ row }" type="flex" align="middle">
                  <el-col :span="24">
                    <el-row align="top">
                      <el-col :span="24" style="padding: 35px">
                        <div class="a-flex">
                          <div>
                            <img :src="row.icon" alt="" class="img-wh" />
                          </div>
                          <div style="margin-left: 20px">
                            <div class="txt1">{{ row.name }}</div>
                            <div class="a-flex mb-20">
                              <div class="list-t mr-40">
                                软件类别：<span
                                  ><el-tag
                                    class="tag-wh"
                                    :type="
                                      row.typeNames === '计价软件'
                                        ? ''
                                        : '算量软件'
                                        ? 'warning'
                                        : 'success'
                                    "
                                    >{{ row.typeNames }}</el-tag
                                  ></span
                                >
                              </div>
                              <div class="list-t mr-40">
                                支持平台：<span>{{ row.platform }}</span>
                              </div>
                              <div class="list-t mr-40">
                                文件大小：<span>{{ row.size }}</span>
                              </div>
                              <div class="list-t">
                                更新时间：<span>{{ row.updateTime }}</span>
                              </div>
                            </div>
                            <div class="txt-wh text-ellipsis">
                              {{ row.description }}
                            </div>
                          </div>
                          <div class="btn-download">
                            <button
                              class="btn-wh"
                              @click="onDownload(row.jumpUrl)"
                            >
                              去下载
                            </button>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-table-column>
            </el-table>
              <el-empty :image="require('@/assets/images/bjtsy/no_data.png')" :image-size="400" v-else></el-empty>
          </el-row>
          <!-- 分页 -->
          <div class="flex project_page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  urlSoftwareMrgPage,
  urlCommonCategoryGetTypeIsTree,
} from "@/api/engineering";
export default {
  data() {
    return {
      tableData: [],
      expandedRows: [],
      activeDateIdx: "",
      form: {
        code: "",
      },
      current: 1,
      size: 20,
      total: 0,
      dateList: [{ name: "全部", id: "" }],
      loading: false
    };
  },
  //   ? '资料软件': 'success'? '算量软件': 'warning'/
  mounted() {
    this.getCommonCategoryGetTypeIsTree();
    this.getSoftwareMrgPage();
  },
  methods: {
    async getCommonCategoryGetTypeIsTree() {
      const param = `type=造价咨询`;
      const res = await urlCommonCategoryGetTypeIsTree(param);
      this.dateList = this.dateList.concat(res.data);
    },
    onDownload(url) {
      window.open(url, "_blank");
    },
    async getSoftwareMrgPage() {
      this.loading = true
      const param = {
        current: this.current,
        name: this.form.code,
        typeIds: this.activeDateIdx,
        size: this.size,
      };
      const res = await urlSoftwareMrgPage(param);
      const { records, size, total } = res.data;
      this.size = size;
      this.total = total;
      this.tableData = records;
      this.loading = false
    },
    handDate(id) {
      if (this.activeDateIdx === id) return;
      this.activeDateIdx = id;
      this.getSoftwareMrgPage();
    },
    // 重置
    handleReset() {
      this.$refs.form.resetFields();
      this.form.code = "";
      this.getSoftwareMrgPage();
    },
    handleSizeChange(e) {
      this.size = e;
      this.getSoftwareMrgPage();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getSoftwareMrgPage();
    },
  },
};
</script>

<style scoped>
.is_active {
  color: #007ef2;
  background-color: #edf4ff;
  border-radius: 5px;
}
.a-flex {
  display: flex;
}

.afw-b {
  font-weight: bolder;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.fs-18 {
  font-size: 18px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mr-40 {
  margin-right: 40px;
}
.border-b {
  width: 100%;
  height: 135px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #edf4ff;
  text-align: left;
  padding: 20px;
}
.txt-wh {
  width: 840px;
  height: 38px;
  font-size: 16px;
  color: #21262d;
  line-height: 20px;
}
.txt1 {
  font-size: 24px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #21262d;
  margin-bottom: 20px;
}
.tag-wh {
  width: 68px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px 4px 4px 4px;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.img-wh {
  width: 80px;
  height: 80px;
}
.btn-download {
  margin-left: 57px;
}
.btn-wh {
  width: 120px;
  height: 40px;
  background: #edf4ff;
  border-radius: 20px 20px 20px 20px;
  font-size: 20px;
  color: #007ef2;
  line-height: 20px;
  border: none;
  cursor: pointer;
}
.list-t {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #888888;
}
.project_page {
  justify-content: flex-end;
  margin-top: 30px;
}

.flex {
  display: flex;
}
::v-deep .el-form-item__label {
  line-height: 32px;
}
::v-deep .el-form-item__content {
  line-height: 32px;
  position: relative;
  font-size: 16px;
}
::v-deep .el-form-item__label {
  font-size: 16px;
}
</style>
